import React, { useEffect, useState } from 'react'
import { MainURL } from '../../../API';
import { Link } from 'react-router-dom';

export default function Ifream() {
  const [key, setKey] = useState(0);

  const [count, setCount] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setCount((count) => count + 1);
      // console.clear()

      update_fun()
    }, 1000);
  });
  // //console.log(count)
  const update_fun = () => {
    //  //console.log(localStorage.getItem("update_ifream"))
    if (localStorage.getItem("update_ifream") != key) {
      setKey(localStorage.getItem("update_ifream"))
    }

  };
  const [product_id, setproduct_id] = useState(0);

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has("product_id")) {
      setproduct_id(urlParams.get("product_id"))
    }

  }, []);

  return (

    <div style={{backgroundColor:"white"}} >
      <br />


      <div className=" uk-text-right " style={{marginRight:"24px"}}>
        <Link

          to="/admin/product"
          className="uk-button uk-button-danger custombtn"
        >
          Go Back
        </Link>
      </div>
      <div>
        <br />  
        <iframe
        style={{ "width": "100%", "height": "1150px" }} key={key}
        src={`${MainURL}api/builder/builder_filter/?productid=${product_id}`} ></iframe></div>


    </div>


  )
}
