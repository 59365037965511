import React, { useEffect, useState } from "react";
import CheckAuthoristion from "../CheckAuthoristion";
import Header from "../Header";
import { Link } from "react-router-dom";
import axios from "axios";
import Select from "react-select";

import {
  GetAllSignalsTypesAPI,
  key,
  UpdateProductAPI,
  GetAllDomainAPI,
  GetProductAPI,
  add_all_customsignals_form_admin,
} from "../../../API";
import { ToastContainer, toast } from "react-toastify";

export default function ProductUpdate() {
  let API = GetAllSignalsTypesAPI;
  const [signals_types, set_signals_types] = useState([]);
  const [all_notes, set_all_notes] = useState([]);

  const [allDomain, setAllDomain] = useState([]);
  const [loadeing, setLoadeing] = useState(false);
  const [allDomainSelect, setAllDomainSelect] = useState([]);


  const fecthApiData = async (url) => {
    try {
      const response = await axios.post(url, {
        key: key,
        admin_token: localStorage.getItem("admin_token"),
      });
      //console.log(response);
      if (response.status === 200) {
        set_signals_types(response.data.data);
      }
    } catch (error) {
      // successMessage("sorry! sever error");
    }

    try {
      const response = await axios.post(GetAllDomainAPI, {
        key: key,
        admin_token: localStorage.getItem("admin_token"),
      });
      //console.log(response.data.data);

      if (response.status === 200) {
        setAllDomain(response.data.data);
        setLoadeing(true);

        var temp = [];
        response.data.data.filter((i) => {
          // //console.log(i)
          temp.push({ value: i.targeturl, label: i.targeturl });
          return 0;
        });
        setAllDomainSelect(temp)
      }

    } catch (error) {

      setLoadeing(true);
    }
  };

  const [name, setName] = useState("");
  const [anyDR, setAnyDR] = useState("");
  const [minDR, setMinDR] = useState("");
  const [maxDR, SetMaxDR] = useState("");
  const [type, setType] = useState("");
  const [indexed, setIndexed] = useState("");

  const [dofollow, setDofollow] = useState("");
  const [status, setStatus] = useState("");
  const [anyAverageTraffic, setAnyAverageTraffic] = useState("");
  const [minAverageTraffic, setMinAverageTraffic] = useState("");
  const [maxAverageTraffic, setMaxAverageTraffic] = useState("");
  const [domain, setDomain] = useState("");
  const [domainSelect, setDomainSelect] = useState([]);

  const [maxDomain, setMaxDomain] = useState("");




  const getAllDataAPI = async () => {
    try {
      // //console.log("**************************************")
      // //console.log(localStorage.getItem("updatation_id_for_product"))

      const response = await axios.post(GetProductAPI, {
        key: key,
        id: localStorage.getItem("updatation_id_for_product"),
        admin_token: localStorage.getItem("admin_token"),
      });
      console.log("**************************************")
      console.log(response.data);




      setName(response.data.data[0].name);
      setAnyDR(response.data.data[0].any_dr);


      if (response.data.data[0].any_dr == "1") {

        document.getElementById("any_dr").checked = true;

        document.getElementById("min_dr").readOnly = true;
        document.getElementById("max_dr").readOnly = true;
      }
      setMinDR(response.data.data[0].drmin);
      SetMaxDR(response.data.data[0].drmax);
      setType(response.data.data[0].signal_type);
      localStorage.setItem("update_type_values", response.data.data[0].signal_type);

      // console.clear();
      if (response.data.data[0].signal_type == null) {
        //console.log("df");
        setType("0");
      }

      //console.log(response.data.data[0].signal_type);

      setIndexed(response.data.data[0].indexed);
      localStorage.setItem("update_indexed_values", response.data.data[0].indexed);

      setDofollow(response.data.data[0].dofollow);
      localStorage.setItem("update_dofollow_values", response.data.data[0].dofollow);

      setStatus(response.data.data[0].p_statues);
      localStorage.setItem("update_status_values", response.data.data[0].p_statues);

      //console.log(response.data.data[0].p_statues);
      setAnyAverageTraffic(response.data.data[0].any_average_traffic);

      if (response.data.data[0].any_average_traffic == "1") {
        document.getElementById("any_average_traffic").checked = true;
        document.getElementById("min_average_traffic").readOnly = true;
        document.getElementById("max_average_traffic").readOnly = true;
      }
      setMaxAverageTraffic(response.data.data[0].max_average_traffic);
      setMinAverageTraffic(response.data.data[0].min_average_traffic);
      //console.log(response.data.data[0].domain)


      if (response.data.data[0].domain == null || response.data.data[0].domain == "") {
        setDomain("-1");
        localStorage.setItem("update_domain_values", "");
        console.log("not t null")

      }
      else {
        setDomain(response.data.data[0].domain);
        localStorage.setItem("update_domain_values", response.data.data[0].domain);
        const myArray = response.data.data[0].domain.split(",");
        var temp = [];
        myArray.filter((i) => {
          console.log(i)
          temp.push({ value: i, label: i });

        });
 
        setDomainSelect(temp)
      }

      console.log(response.data.data[0].domain)





      setMaxDomain(response.data.data[0].max_domain);







      console.log(response.data.data[0].any_rd)

      if (response.data.data[0].any_rd == "1") {

        document.getElementById("any_rd").checked = true;
        document.getElementById("min_rd").readOnly = true;
        document.getElementById("max_rd").readOnly = true;


        setAnyRD(1)

      } else {
        SetMinRD(response.data.data[0].min_rd)
        SetMaxRD(response.data.data[0].max_rd)


      }


      if (response.data.data[0].any_accept_link_insertion == "1") {
        document.getElementById("AcceptLinkInsertions").disabled = true;
        document.getElementById("anyAcceptLinkInsertions").checked = true;
        setAnyAcceptLinkInsertions(1)

      }
      else {
        document.getElementById("AcceptLinkInsertions").value = response.data.data[0].accept_link_insertion
        setAcceptLinkInsertions(response.data.data[0].accept_link_insertion)
      }

      if (response.data.data[0].any_accept_authorship == "1") {
        document.getElementById("AcceptAuthorship").disabled = true;
        document.getElementById("AnyAcceptAuthorship").checked = true;
        setAnyAcceptAuthorship(1)


      }
      else {
        console.log(response.data.data[0].accept_authorship)
        document.getElementById("AcceptAuthorship").value = response.data.data[0].accept_authorship
        setAcceptAuthorship(response.data.data[0].accept_authorship)
      }

      if (response.data.data[0].any_third_party_check == "1") {
        document.getElementById("ThirdPartyCheck").disabled = true;
        document.getElementById("AnyThirdPartyCheck").checked = true;
        setAnyThirdPartyCheck(1)

      }
      else {
        document.getElementById("ThirdPartyCheck").value = response.data.data[0].third_party_check
        setThirdPartyCheck(response.data.data[0].third_party_check)
      }

      // if(response.data.data[0].niche_name.length()>0){

      if (response.data.data[0].any_niche == "1") {
        document.getElementById("any_niche").checked = true;
        localStorage.setItem('set_updated_niche_values', '')

        setAnyNiche(1)


      } else {
        var temp = [];
        response.data.data[0].niche_name.filter((i) => {
          // //console.log(i)
          temp.push({ value: i.id, label: i.name });
          return 0;
        });
        setSelectedNiche(temp)
        localStorage.setItem('set_updated_niche_values', response.data.data[0].niche)

      }


      if (response.data.data[0].any_sub_niche == "1") {
        document.getElementById("any_sub_niche").checked = true;
        localStorage.setItem('set_updated_sub_niche_values', '')
        setAnySubNiche(1)

      } else {
        var temp = [];
        response.data.data[0].sub_niche_name.filter((i) => {
          // //console.log(i)
          temp.push({ value: i.id, label: i.name });
          return 0;
        });
        setSelectedSubNiche(temp)
        localStorage.setItem('set_updated_sub_niche_values', response.data.data[0].sub_niche)

      }






      if (response.data.aac_data) {
        if (response.data.aac_data.length > 0) {
          console.log("aac_data")
          set_show_add_additional_conditional_form(true)
          if (response.data.aac_data[0].any_dr === '1')
            setAnyDR_aac(true)
          setMaxDR_aac(response.data.aac_data[0].drmax)
          setMinDR_aac(response.data.aac_data[0].drmin)

          if (response.data.aac_data[0].any_rd === '1')
            setAnyRD_aac(true)
          setMinRD_aac(response.data.aac_data[0].min_rd)
          setMaxRD_aac(response.data.aac_data[0].max_rd)

          if (response.data.aac_data[0].any_average_traffic === '1')
            setAnyAverageTraffic_aac(true)
          setMinAverageTraffic_aac(response.data.aac_data[0].min_average_traffic)
          setMaxAverageTraffic_aac(response.data.aac_data[0].max_average_traffic)

          setType_aac(response.data.aac_data[0].signal_type);
          localStorage.setItem("update_type_values_aac", response.data.aac_data[0].signal_type);

          setIndexed_aac(response.data.aac_data[0].indexed);
          localStorage.setItem("update_indexed_values_aac", response.data.aac_data[0].indexed);

          setDofollow_aac(response.data.aac_data[0].dofollow);
          localStorage.setItem("update_dofollow_values_aac", response.data.aac_data[0].dofollow);

          setStatus_aac(response.data.aac_data[0].p_statues);
          localStorage.setItem("update_status_values_aac", response.data.aac_data[0].p_statues);
          if (response.data.aac_data[0].domain) {
            localStorage.setItem("update_domain_values_aac", response.data.aac_data[0].domain);
          }
          else {
            localStorage.removeItem("update_domain_values_aac");
          }

          if (response.data.aac_data[0].domain) {
            const myArray = response.data.aac_data[0].domain.split(",");
            let DomainSelect_aactemp = [];
            myArray.filter((i) => {
              DomainSelect_aactemp.push({ value: i, label: i });
              return 0;
            });

            setDomainSelect_aac(DomainSelect_aactemp)
            setMaxDomain_aac(response.data.aac_data[0].max_domain)



          }

          // console.log("dddddddddddddddddddddddddddddddddd")

          if (response.data.aac_data[0].any_third_party_check === 1)
            setAnyThirdPartyCheck_aac(true)
          setThirdPartyCheck_aac(response.data.aac_data[0].third_party_check)

          if (response.data.aac_data[0].any_accept_link_insertion === '1')
            setAnyAcceptLinkInsertions_aac(true)
          setAcceptLinkInsertions_aac(response.data.aac_data[0].accept_link_insertion)


          if (response.data.aac_data[0].any_accept_authorship === 1)
            setAnyAcceptAuthorship_aac(true)
          setAcceptAuthorship_aac(response.data.aac_data[0].accept_authorship)


          if (response.data.aac_data[0].any_niche == "1")
            setAnyNiche_aac(true)



          let nicheAACTemp = [];
          response.data.aac_data[0].niche_name.filter((i) => {

            nicheAACTemp.push({ value: i.id, label: i.name });
            return 0;
          });
          setNicheSelect_aac(nicheAACTemp)

          if (response.data.aac_data[0].any_sub_niche == "1")
            setSubAnyNiche_aac(true)


          let setNicheAACTemp = [];

          response.data.aac_data[0].sub_niche_name.filter((i) => {
            setNicheAACTemp.push({ value: i.id, label: i.name });
            return 0;
          });
          setSubNicheSelect_aac(setNicheAACTemp)



          setDataType(response.data.aac_data[0].or_not_and_type)


        }
      }

    } catch (error) {
      //console.log(error);
    }




  };

  useEffect(() => {
    localStorage.setItem("update_indexed_values", "");
    localStorage.setItem("update_dofollow_values", "");
    localStorage.setItem("update_type_values", "");
    localStorage.setItem("update_status_values", "");
    localStorage.setItem("update_domain_values", "");


    getAllDataAPI();
    fecthApiData(API);

  }, []);











  const [allNiche, setAllNiche] = useState([]);

  useEffect(() => {
    // Define an asynchronous function to fetch data
    const fetchData = async () => {
      try {
        const response = await axios.post(add_all_customsignals_form_admin, {
          key: key,
          admin_token: localStorage.getItem("admin_token"),
          type: "niche",
        });

        // Check if the response status is 200 (OK)
        if (response.status === 200) {
          // Set the retrieved data in the component's state
          //console.log('get  all niche')


          //console.log(response.data.data)

          var temp = [];
          response.data.data.filter((i) => {
            // //console.log(i)
            temp.push({ value: i.id, label: i.name });
            return 0;
          });
          setAllNiche(temp)






        } else {
          // Handle unexpected status codes if necessary
          console.error("Unexpected response status:", response.status);
        }
      } catch (error) {
        // Handle errors gracefully, e.g., show an error message to the user
        console.error("An error occurred:", error);

      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []);





  const [allSubNiche, setAllSubNiche] = useState([]);

  useEffect(() => {
    // Define an asynchronous function to fetch data
    const fetchData = async () => {
      try {
        const response = await axios.post(add_all_customsignals_form_admin, {
          key: key,
          admin_token: localStorage.getItem("admin_token"),
          type: "sub_niche",
        });

        // Check if the response status is 200 (OK)
        if (response.status === 200) {


          var temp = [];
          response.data.data.filter((i) => {
            // //console.log(i)
            temp.push({ value: i.id, label: i.name });
            return 0;
          });
          setAllSubNiche(temp)

        }
      } catch (error) {
        // Handle errors gracefully, e.g., show an error message to the user
        console.error("An error occurred:", error);

      }
      try {
        const response = await axios.post(add_all_customsignals_form_admin, {
          key: key,
          admin_token: localStorage.getItem("admin_token"),
          type: "notes",
        });
        //console.log(response)
        if (response.status === 200) {
          set_all_notes(response.data.data);
        }
      } catch (error) {

      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []);


  const inputEventName = (event) => {
    //console.log(event.target.value);
    setName(event.target.value);
  };
  const inputEventAnyDR = (event) => {
    //console.log(event.target.checked);
    if (event.target.checked) {
      setAnyDR(1);
      document.getElementById("min_dr").readOnly = true;
      document.getElementById("max_dr").readOnly = true;
    } else {
      //console.log("Dd");

      setAnyDR(0);
      document.getElementById("min_dr").readOnly = false;
      document.getElementById("max_dr").readOnly = false;
    }

  };
  const inputEventMinDR = (event) => {
    //console.log(event.target.value);
    setMinDR(event.target.value);
  };
  const inputEventMaxDR = (event) => {
    //console.log(event.target.value);
    SetMaxDR(event.target.value);
  };




  const [anyRD, setAnyRD] = useState(0);

  const inputEventAnyRd = (event) => {
    //console.log(event.target.checked)
    if (event.target.checked) {
      setAnyRD(1)
      document.getElementById("min_rd").readOnly = true;
      document.getElementById("max_rd").readOnly = true;
    }
    else {
      //console.log("Dd")
      setAnyRD(0)
      document.getElementById("min_rd").readOnly = false;
      document.getElementById("max_rd").readOnly = false;
    }
  }

  const [minRD, SetMinRD] = useState("");

  const [maxRD, SetMaxRD] = useState("");

  const inputEventMinRD = (event) => {
    //console.log(event.target.value)
    SetMinRD(event.target.value)
  }
  const inputEventMaxRD = (event) => {
    //console.log(event.target.value)
    SetMaxRD(event.target.value)
  }

  const [anyNiche, setAnyNiche] = useState(0);
  const [selectedNiche, setSelectedNiche] = useState([]);

  const inputEventAnyNiche = (event) => {
    //console.log(event.target.checked)
    if (event.target.checked) {
      setAnyNiche(1)

    }
    else {
      //console.log("Dd")
      setAnyNiche(0)

    }
  }

  const inputEventNiche = (event) => {
    //console.log(event)
    setSelectedNiche(event)

    var values = []
    event.filter((i) => {
      // //console.log(i)
      values.push(i.value);
      return 0;
    });
    // setDomain(values);

    localStorage.setItem("set_updated_niche_values", values)
    //console.log(values)
  }


  const [anySubNiche, setAnySubNiche] = useState(0);
  const [selectedSubNiche, setSelectedSubNiche] = useState([]);

  const inputEventAnySubNiche = (event) => {
    //console.log(event.target.checked)
    if (event.target.checked) {
      setAnySubNiche(1)

    }
    else {

      setAnySubNiche(0)

    }
  }
  const inputEventSubNiche = (event) => {
    //console.log(event)
    setSelectedSubNiche(event)

    var values = []
    event.filter((i) => {
      // //console.log(i)
      values.push(i.value);
      return 0;
    });
    // setDomain(values);

    localStorage.setItem("set_updated_sub_niche_values", values)
    //console.log(values)
  }


  const [anyAcceptLinkInsertions, setAnyAcceptLinkInsertions] = useState(0);
  const inputEventAnyAcceptLinkInsertions = (event) => {
    //console.log(event.target.checked)
    if (event.target.checked) {
      setAnyAcceptLinkInsertions(1)
      document.getElementById("AcceptLinkInsertions").disabled = true;
    }
    else {
      setAnyAcceptLinkInsertions(0)
      document.getElementById("AcceptLinkInsertions").disabled = false;
    }
  }
  const [acceptLinkInsertions, setAcceptLinkInsertions] = useState(0);
  const inputEventAcceptLinkInsertions = (event) => {
    setAcceptLinkInsertions(event.target.value)
  }

  const [anyAcceptAuthorship, setAnyAcceptAuthorship] = useState(0);
  const inputEventAnyAcceptAuthorship = (event) => {
    //console.log(event.target.checked)
    if (event.target.checked) {
      setAnyAcceptAuthorship(1)
      document.getElementById("AcceptAuthorship").disabled = true;
    }
    else {
      setAnyAcceptAuthorship(0)
      document.getElementById("AcceptAuthorship").disabled = false;
    }
  }
  const [acceptAuthorship, setAcceptAuthorship] = useState("");
  const inputEventAcceptAuthorship = (event) => {
    setAcceptAuthorship(event.target.value)
  }
  const [allDomainSelected, setAllDomainSelected] = useState(false);



  const [anyThirdPartyCheck, setAnyThirdPartyCheck] = useState(0);
  const inputEventAnyThirdPartyCheck = (event) => {
    //console.log(event.target.checked)
    if (event.target.checked) {
      setAnyThirdPartyCheck(1)
      document.getElementById("ThirdPartyCheck").disabled = true;
    }
    else {
      setAnyThirdPartyCheck(0)
      document.getElementById("ThirdPartyCheck").disabled = false;
    }
  }
  const [ThirdPartyCheck, setThirdPartyCheck] = useState("");
  const inputEventThirdPartyCheck = (event) => {
    setThirdPartyCheck(event.target.value)
  }






















  const inputEventTypes = (event) => {


    //console.log(event.target.selectedOptions);

    var values = Array.from(event.target.selectedOptions).map(
      ({ value }) => value,
    );
    //console.log(values);
    setType(values);
    localStorage.setItem("update_type_values", values);
    //console.log(localStorage.getItem("update_type_values"));
    //console.log(type);
  };

  const inputEventIndexed = (event) => {
    // //console.log(event.target.value);
    var values = Array.from(event.target.selectedOptions).map(
      ({ value }) => value,
    );
    localStorage.setItem("update_indexed_values", values);

    setIndexed(values);
  };

  const inputEventDofollow = (event) => {


    var values = Array.from(event.target.selectedOptions).map(
      ({ value }) => value,
    );
    localStorage.setItem("update_dofollow_values", values);

    setDofollow(values);
  };

  const inputEventStatus = (event) => {

    var values = Array.from(event.target.selectedOptions).map(
      ({ value }) => value,
    );
    localStorage.setItem("update_status_values", values);

    setStatus(values);
  };

  const inputEventAnyAverageTraffic = (event) => {
    //console.log(event.target.checked);
    if (event.target.checked) {
      setAnyAverageTraffic(1);
      document.getElementById("min_average_traffic").readOnly = true;
      document.getElementById("max_average_traffic").readOnly = true;
    } else {


      setAnyAverageTraffic(0);
      document.getElementById("min_average_traffic").readOnly = false;
      document.getElementById("max_average_traffic").readOnly = false;
    }

  };
  const inputEventMinAverageTraffic = (event) => {
    // //console.log(event.target.value);
    setMinAverageTraffic(event.target.value);
  };
  const inputEventMaxAverageTraffic = (event) => {
    // //console.log(event.target.value);
    setMaxAverageTraffic(event.target.value);
  };

  const inputEventDomin = (event) => {

    // var values = Array.from(event.target.selectedOptions).map(
    //   ({ value }) => value,
    // );
    // //console.log(values);
    var values = []
    event.filter((i) => {
      // //console.log(i)
      values.push(i.value);
      return 0;
    });
    setDomain(values);
    setDomainSelect(event)
    // //console.log(values);


    localStorage.setItem("update_domain_values", values);
  };

  const inputEventMaxDomain = (event) => {
    // //console.log(event.target.value);
    setMaxDomain(event.target.value);
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    // //console.log('acceptLinkInsertions'+acceptLinkInsertions)
    // //console.log('acceptLinkInsertions'+acceptLinkInsertions)

    if (name.replace(" ", "") == "") {
      showToastMessageError("please enter valid name");
    }
    else if (localStorage.getItem("update_indexed_values") == "" || localStorage.getItem("update_dofollow_values") == "" || localStorage.getItem("update_type_values") == "" || localStorage.getItem("update_status_values") == "") {
      showToastMessageError("please fill all fields");
    }
    else if (anyNiche == "0" && selectedNiche.length == 0) {
      showToastMessageError("please select niche")
    }

    else if (anySubNiche == "0" && selectedSubNiche.length == 0) {
      showToastMessageError("please select sub niche")
    }
    else {
      // if (anyDR == "1") {
      //   setMinDR("0");
      //   SetMaxDR("0");
      // }
      // //console.log("anyDR" + anyRD)
      // //console.log("minAverageTraffic" + minAverageTraffic)

      // if (anyAverageTraffic == "1") {
      //   setMaxAverageTraffic("0");
      //   setMinAverageTraffic("0");
      // }


      console.log(dataType)
      try {
        const response = await axios.post(UpdateProductAPI, {
          key: key,
          name: name,

          anyDR: anyDR,
          minDR: anyDR == "1" ? null : minDR,
          maxDR: anyDR == "1" ? null : maxDR,

          type: localStorage.getItem("update_type_values"),
          indexed: localStorage.getItem("update_indexed_values"),
          dofollow: localStorage.getItem("update_dofollow_values"),
          status: localStorage.getItem("update_status_values"),

          anyAverageTraffic: anyAverageTraffic,
          minAverageTraffic: minAverageTraffic,
          maxAverageTraffic: maxAverageTraffic,

          domain: localStorage.getItem("update_domain_values"),
          anyRD: anyRD,

          minRD: anyRD == "1" ? null : minRD,
          maxRD: anyRD == "1" ? null : maxRD,

          anyNiche: anyNiche,
          niche: localStorage.getItem("set_updated_niche_values"),
          anySubNiche: anySubNiche,
          subNiche: localStorage.getItem("set_updated_sub_niche_values"),
          anyAcceptLinkInsertions: anyAcceptLinkInsertions,
          acceptLinkInsertions: acceptLinkInsertions,
          anyAcceptAuthorship: anyAcceptAuthorship,
          acceptAuthorship: acceptAuthorship,
          anyThirdPartyCheck: anyThirdPartyCheck,
          ThirdPartyCheck: ThirdPartyCheck,
          maxDomain: maxDomain,












          anyDRAAC: anyDR_aac,
          minDRAAC: anyDR_aac ? null : minDR_aac,
          maxDRAAC: anyDR_aac ? null : maxDR_aac,

          typeAAC: localStorage.getItem("update_type_values_aac"),
          indexedAAC: localStorage.getItem("update_indexed_values_aac"),
          dofollowAAC: localStorage.getItem("update_dofollow_values_aac"),
          statusAAC: localStorage.getItem("update_status_values_aac"),

          anyAverageTrafficAAC: anyAverageTraffic_aac,
          minAverageTrafficAAC: anyAverageTraffic_aac ? null : minAverageTraffic_aac,
          maxAverageTrafficAAC: anyAverageTraffic_aac ? null : maxAverageTraffic_aac,

          domainAAC: localStorage.getItem("update_domain_values_aac"),
          maxDomainAAC: maxDomain_aac,
          anyRDAAC: anyRD_aac ? "1" : "0",
          minRDAAC: anyRD_aac ? null : minRD_aac,
          maxRDAAC: anyRD_aac ? null : maxRD_aac,
          anyNicheAAC: anyNiche_aac,
          nicheAAC: anyNiche_aac ? null : localStorage.getItem("update_niche_values_aac"),
          anySubNicheAAC: anySubNiche_aac,

          subNicheAAC: anySubNiche_aac ? null : localStorage.getItem("update_sub_niche_values_aac"),

          anyAcceptLinkInsertionsAAC: anyAcceptLinkInsertions_aac ? '1' : '0',
          acceptLinkInsertionsAAC: anyAcceptLinkInsertions_aac ? null : AcceptLinkInsertions_aac,

          anyAcceptAuthorshipAAC: anyAcceptAuthorship_aac ? '1' : '0',
          acceptAuthorshipAAC: anyAcceptAuthorship_aac ? null : AcceptAuthorship_aac,

          anyThirdPartyCheckAAC: anyThirdPartyCheck_aac ? '1' : '0',
          ThirdPartyCheckAAC: anyThirdPartyCheck_aac ? null : ThirdPartyCheck_aac,

          or_and_not_value: dataType,
          aacType: show_add_additional_conditional_form ? '1' : '0',


          id: localStorage.getItem("updatation_id_for_product"),
          admin_id: localStorage.getItem("admin_id"),
          admin_token: localStorage.getItem("admin_token"),
        });
        // showToastMessage(response.data.message)

        if (response.data.status_code === 200) {
          //console.log(response.data.token);
          showToastMessage(response.data.message);


        } else {
          showToastMessageError(response.data.message);
        }
      } catch (error) {

        showToastMessageError("error");
      }
    }
  };
  const showToastMessage = (text) => {
    toast.success(text, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const showToastMessageError = (text) => {
    toast.error(text, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };



  // add additional conditional  **********************************************************************


  const [show_add_additional_conditional_form, set_show_add_additional_conditional_form] = useState(false);

  const [name_aac, setName_aac] = useState("");
  const [anyDR_aac, setAnyDR_aac] = useState(false);
  const [minDR_aac, setMinDR_aac] = useState(null);
  const [maxDR_aac, setMaxDR_aac] = useState(null);

  const [anyRD_aac, setAnyRD_aac] = useState(false);
  const [minRD_aac, setMinRD_aac] = useState(null);
  const [maxRD_aac, setMaxRD_aac] = useState(null);

  const [anyAverageTraffic_aac, setAnyAverageTraffic_aac] = useState(false);
  const [minAverageTraffic_aac, setMinAverageTraffic_aac] = useState(null);
  const [maxAverageTraffic_aac, setMaxAverageTraffic_aac] = useState(null);


  const [type_aac, setType_aac] = useState("");
  const [indexed_aac, setIndexed_aac] = useState("");
  const [dofollow_aac, setDofollow_aac] = useState("");
  const [status_aac, setStatus_aac] = useState("");

  const [domainSelect_aac, setDomainSelect_aac] = useState([]);
  const [nicheSelect_aac, setNicheSelect_aac] = useState([]);
  const [subNicheSelect_aac, setSubNicheSelect_aac] = useState([]);


  const [dataType, setDataType] = useState(null);



  const [maxDomain_aac, setMaxDomain_aac] = useState(null);


  const inputEventIndexedAAC = (event) => {

    var values = Array.from(event.target.selectedOptions).map(({ value }) => value);
    localStorage.setItem("update_indexed_values_aac", values)
  }
  const inputEventStatusAAC = (event) => {
    var values = Array.from(event.target.selectedOptions).map(({ value }) => value);
    localStorage.setItem("update_status_values_aac", values)
  }
  const inputEventDofollowAAC = (event) => {
    var values = Array.from(event.target.selectedOptions).map(({ value }) => value);
    localStorage.setItem("update_dofollow_values_aac", values)
  }
  const inputEventTypesAAC = (event) => {
    var values = Array.from(event.target.selectedOptions).map(({ value }) => value);
    localStorage.setItem("update_type_values_aac", values)
  }

  const inputEventDominAAC = (event) => {
    setDomainSelect_aac(event)
    var values = []
    event.filter((i) => {
      values.push(i.value);
      return 0;
    });

    localStorage.setItem("update_domain_values_aac", values)
  }
  const [anyThirdPartyCheck_aac, setAnyThirdPartyCheck_aac] = useState(false);
  const [ThirdPartyCheck_aac, setThirdPartyCheck_aac] = useState(null);

  const [anyAcceptLinkInsertions_aac, setAnyAcceptLinkInsertions_aac] = useState(false);
  const [AcceptLinkInsertions_aac, setAcceptLinkInsertions_aac] = useState(null);

  const [anyAcceptAuthorship_aac, setAnyAcceptAuthorship_aac] = useState(false);
  const [AcceptAuthorship_aac, setAcceptAuthorship_aac] = useState(null);

  const [anyNiche_aac, setAnyNiche_aac] = useState(false);
  const inputEventNicheAAC = (event) => {
    setNicheSelect_aac(event)
    var values = []
    event.filter((i) => {
      values.push(i.value);
      return 0;
    });
    localStorage.setItem("update_niche_values_aac", values)
  }

  const [anySubNiche_aac, setSubAnyNiche_aac] = useState(false);
  const inputEventSubNicheAAC = (event) => {
    setSubNicheSelect_aac(event)

    var values = []
    event.filter((i) => {
      values.push(i.value);
      return 0;
    });
    localStorage.setItem("update_sub_niche_values_aac", values)
  }


  useEffect(() => {
    // Define an asynchronous function to fetch data
    localStorage.removeItem("update_domain_values_aac")
    localStorage.removeItem("update_domain_values")
    localStorage.removeItem("update_sub_niche_values_aac")
    localStorage.removeItem("update_niche_values_aac")
    localStorage.removeItem("update_sub_niche_values")
    localStorage.removeItem("update_niche_values")

  }, []);























  return (
    <div>
      <CheckAuthoristion />
      <div className="background">
        <div className="container">
          <div className="uk-width-1-1">
            <div className="uk-grid-large" uk-grid="">


              <div className="uk-width-expand@m content_div">
                <Header product="uk-active" />

                <div className="contentdiv">
                  <div className="" uk-grid="">
                    <div className="uk-width-expand@m">
                      <div className="" uk-grid="">
                        <div className="uk-width-auto">
                          <h3 className="ukhd"> Update Product</h3>
                        </div>
                        <div className="uk-width-expand">
                          <div className="uk-width-1-1@s uk-text-right uk-margin-small-bottom">
                            <Link
                              to="/admin/product"
                              className="uk-button uk-button-danger custombtn"
                            >
                              <i className="fa fa-arrow-left" /> Go Back{" "}
                            </Link>
                          </div>
                        </div>
                      </div>
                      {false ? (
                        <>
                          <div>
                            <center>
                              <span uk-spinner="ratio: 4.5"></span>
                              <h1> Please wait... </h1>
                            </center>
                          </div>
                        </>
                      ) : (
                        <>

                          <div style={{ display: !loadeing ? "" : "none" }}>
                            <center>
                              <span uk-spinner="ratio: 4.5"></span>
                              <h1> Please wait... </h1>
                            </center>
                          </div>


                          <div className="uk-card uk-card-body uk-card-default addcandidate" style={{ display: loadeing ? "" : "none" }}>
                            <h3 className="ukshd">

                            </h3>
                            <form
                              onSubmit={onSubmit}
                              method="post"
                              encType="multipart/form-data"
                            >


                              <div className="uk-grid-small" uk-grid="">
                                <div className="uk-width-1-2@s">
                                  <div className="uk-grid-small" uk-grid="">
                                    <div className="uk-width-1-2@s">
                                      <div className="uk-margin">
                                        <label className="uk-form-label">
                                          {" "}
                                          Name{" "}
                                          <span className="uk-text-danger">*</span>
                                        </label>
                                        <div className="uk-form-controls">
                                          <input
                                            className="uk-input"
                                            name="fname"
                                            type="text"
                                            placeholder="Enter Name"
                                            value={name}
                                            onChange={inputEventName}
                                            required
                                          />
                                        </div>
                                      </div>

                                    </div>
                                    <div className="uk-width-1-2@s">
                                      <div className="uk-grid-small" uk-grid="">
                                        <div className="uk-width-1-4@s">
                                          <div className="uk-margin">
                                            <label className="uk-form-label">
                                              {" "}
                                              Any DR{" "}
                                            </label>
                                            <div className="uk-form-controls">
                                              <input
                                                className="uk-checkbox"
                                                id="any_dr"
                                                type="checkbox"
                                                onChange={inputEventAnyDR}
                                              />
                                            </div>
                                          </div>

                                        </div>
                                        <div className="uk-width-3-4@s">
                                          <div className="uk-grid-small" uk-grid="">
                                            <div className="uk-width-1-2@s">
                                              <div className="uk-margin">
                                                <label className="uk-form-label">
                                                  {" "}
                                                  Min-DR{" "}
                                                </label>
                                                <div className="uk-form-controls">
                                                  <input
                                                    className="uk-input"
                                                    name="lname"
                                                    type="number"
                                                    id={"min_dr"}
                                                    min={0}
                                                    placeholder="Enter Min-DR"
                                                    value={minDR}
                                                    onChange={inputEventMinDR}
                                                    required
                                                  />
                                                </div>
                                              </div>

                                            </div>

                                            <div className="uk-width-1-2@s">
                                              <div className="uk-margin">
                                                <label className="uk-form-label">
                                                  {" "}
                                                  Max-DR{" "}
                                                </label>
                                                <div className="uk-form-controls">
                                                  <input
                                                    className="uk-input"
                                                    name="max_dr"
                                                    id={"max_dr"}
                                                    type="number"
                                                    min={0}
                                                    placeholder="Enter Max-DR"
                                                    value={maxDR}
                                                    onChange={inputEventMaxDR}
                                                    required
                                                  />
                                                </div>
                                              </div>
                                            </div>

                                          </div>

                                        </div>


                                      </div>


                                    </div>

                                  </div>



                                </div>
                                <div className="uk-width-1-2@s">

                                  <div className="uk-grid-small" uk-grid="">
                                    <div className="uk-width-1-2@s">
                                      <div className="uk-grid-small" uk-grid="">
                                        <div className="uk-width-1-4@s">
                                          <div className="uk-margin">
                                            <label className="uk-form-label">
                                              Any RD{" "}
                                              <span className="uk-text-danger"></span>
                                            </label>
                                            <div className="uk-form-controls">
                                              <input
                                                className="uk-checkbox"
                                                type="checkbox"
                                                onChange={inputEventAnyRd}
                                                id={'any_rd'}
                                              />
                                            </div>
                                          </div>

                                        </div>
                                        <div className="uk-width-3-4@s">

                                          <div className="uk-grid-small" uk-grid="">
                                            <div className="uk-width-1-2@s">
                                              <div className="uk-margin">
                                                <label className="uk-form-label">
                                                  {" "}
                                                  Min RD{" "}
                                                  <span className="uk-text-danger">*</span>
                                                </label>
                                                <div className="uk-form-controls">
                                                  <input
                                                    className="uk-input"
                                                    name="password"
                                                    type="number"
                                                    placeholder="Enter Min-RD"
                                                    value={minRD}
                                                    onChange={inputEventMinRD}
                                                    id={"min_rd"}

                                                    required
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="uk-width-1-2@s">
                                              <div className="uk-margin">
                                                <label className="uk-form-label">
                                                  {" "}
                                                  Max RD{" "}
                                                  <span className="uk-text-danger">*</span>
                                                </label>
                                                <div className="uk-form-controls">
                                                  <input
                                                    className="uk-input"
                                                    id={"max_rd"}
                                                    name="max_rd"
                                                    type="number"
                                                    placeholder="Enter Max-RD"
                                                    value={maxRD}
                                                    onChange={inputEventMaxRD}
                                                    required
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>




                                        </div>


                                      </div>


                                    </div>
                                    <div className="uk-width-1-2@s">
                                      <div className="uk-grid-small" uk-grid="">
                                        <div className="uk-width-1-4@s">
                                          <div className="uk-margin">
                                            <label className="uk-form-label">
                                              Any Average Traffic{" "}
                                              <span className="uk-text-danger"></span>
                                            </label>
                                            <div className="uk-form-controls">
                                              <input
                                                className="uk-checkbox"
                                                id={"any_average_traffic"}
                                                type="checkbox"
                                                onChange={inputEventAnyAverageTraffic}
                                              />
                                            </div>
                                          </div>

                                        </div>
                                        <div className="uk-width-3-4@s">
                                          <div className="uk-grid-small" uk-grid="">
                                            <div className="uk-width-1-2@s">
                                              <div className="uk-margin">
                                                <label className="uk-form-label">
                                                  {" "}
                                                  Min-Average Traffic{" "}
                                                  <span className="uk-text-danger">*</span>
                                                </label>
                                                <div className="uk-form-controls">
                                                  <input
                                                    className="uk-input"
                                                    name="password"
                                                    type="number"
                                                    placeholder="Enter Min-Average Traffic"
                                                    value={minAverageTraffic}
                                                    onChange={inputEventMinAverageTraffic}
                                                    id={"min_average_traffic"}
                                                    required
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="uk-width-1-2@s">
                                              <div className="uk-margin">
                                                <label className="uk-form-label">
                                                  {" "}
                                                  Max-Average Traffic{" "}
                                                  <span className="uk-text-danger">*</span>
                                                </label>
                                                <div className="uk-form-controls">
                                                  <input
                                                    className="uk-input"
                                                    id={"max_average_traffic"}
                                                    name="max_average_traffic"
                                                    type="number"
                                                    placeholder="Enter Max-Average Traffic"
                                                    value={maxAverageTraffic}
                                                    onChange={inputEventMaxAverageTraffic}
                                                    required
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>



                                        </div>


                                      </div>


                                    </div>

                                  </div>

                                </div>
                              </div>

                              <div className="uk-grid-small" uk-grid="">


                                <div className="uk-width-1-4@s">
                                  <div className="uk-margin">
                                    <label className="uk-form-label">
                                      Types{" "}
                                      <span className="uk-text-danger">*</span>
                                    </label>
                                    <div className="uk-form-controls">
                                      <select
                                        class="uk-select Post_Name"
                                        name="post_name"
                                        onChange={inputEventTypes}
                                        multiple
                                        required
                                      >

                                        {signals_types.map((i) => {
                                          return (
                                            <option
                                              selected={
                                                type.indexOf(i.id) > -1
                                                  ? true
                                                  : false
                                              }
                                              value={i.id}
                                            >
                                              {i.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="uk-width-1-4@s">
                                  <div className="uk-margin">
                                    <label className="uk-form-label">
                                      Indexed{" "}
                                      <span className="uk-text-danger">*</span>
                                    </label>
                                    <div className="uk-form-controls">
                                      <select
                                        class="uk-select Post_Name"
                                        name="post_name"
                                        onChange={inputEventIndexed}
                                        required

                                        multiple
                                      >

                                        <option
                                          selected={
                                            indexed.indexOf(2) > -1
                                              ? true
                                              : false
                                          }
                                          value="2"
                                        >
                                          Yes{" "}
                                        </option>

                                        <option

                                          selected={
                                            indexed.indexOf(1) > -1
                                              ? true
                                              : false
                                          }
                                          value="1"
                                        >
                                          No{" "}
                                        </option>

                                        <option

                                          selected={
                                            indexed.indexOf(0) > -1
                                              ? true
                                              : false
                                          }
                                          value="0"
                                        >
                                          NA{" "}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="uk-width-1-4@s">
                                  <div className="uk-margin">
                                    <label className="uk-form-label">
                                      Dofollow{" "}
                                      <span className="uk-text-danger">*</span>
                                    </label>
                                    <div className="uk-form-controls">
                                      <select
                                        class="uk-select Post_Name"
                                        name="post_name"
                                        onChange={inputEventDofollow}
                                        multiple
                                        required


                                      >

                                        <option
                                          // 
                                          selected={
                                            dofollow.indexOf(2) > -1
                                              ? true
                                              : false
                                          }
                                          value="2"
                                        >
                                          Yes
                                        </option>

                                        <option

                                          selected={
                                            dofollow.indexOf(1) > -1
                                              ? true
                                              : false
                                          }
                                          value="1"
                                        >
                                          No{" "}
                                        </option>

                                        <option

                                          selected={
                                            dofollow.indexOf(0) > -1
                                              ? true
                                              : false
                                          }
                                          value="0"
                                        >
                                          NA{" "}
                                        </option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                                <div className="uk-width-1-4@s">
                                  <div className="uk-margin">
                                    <label className="uk-form-label">
                                      Status{" "}
                                      <span className="uk-text-danger">*</span>
                                    </label>
                                    <div className="uk-form-controls">
                                      <select
                                        class="uk-select Post_Name"
                                        name="post_name"
                                        onChange={inputEventStatus}
                                        multiple
                                        required
                                      >

                                        {all_notes.map((i) => {
                                          return (
                                            <option
                                              selected={
                                                status.indexOf(i.id) > -1
                                                  ? true
                                                  : false
                                              }
                                              value={i.id}
                                            >
                                              {i.name}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                  {/* <div className="uk-margin">
                                    <label className="uk-form-label">
                                      Status{" "}
                                      <span className="uk-text-danger">*</span>
                                    </label>
                                    <div className="uk-form-controls">
                                      <select
                                        class="uk-select Post_Name"
                                        name="post_name"
                                        onChange={inputEventStatus}
                                        multiple
                                        required

                                      >

                                        <option

                                          selected={
                                            status.indexOf('live') > -1
                                              ? true
                                              : false
                                          }
                                          value="live"
                                        >
                                          Live{" "}
                                        </option>

                                        <option

                                          selected={
                                            status.indexOf('pending') > -1
                                              ? true
                                              : false
                                          }
                                          value="pending"
                                        >
                                          Panding{" "}
                                        </option>

                                        <option
                                          // selected={
                                          //   status == "paid" ? true : false
                                          // }
                                          selected={
                                            status.indexOf('paid') > -1
                                              ? true
                                              : false
                                          }
                                          value="paid"
                                        >
                                          Paid{" "}
                                        </option>
                                      </select>
                                    </div>
                                  </div> */}
                                </div>


                                <div className="uk-width-1-2@s">
                                  <div className="uk-grid-small" uk-grid="">
                                    <div className="uk-width-1-2@s">
                                      <div className="uk-margin">
                                        <label className="uk-form-label">
                                          {" "}
                                          Domain{" "}
                                          {/* <span className="uk-text-danger">*</span> */}
                                        </label>
                                        <div className="uk-form-controls">
                                          {/* <select
                                        class="uk-select Post_Name"
                                        name="post_name"
                                        onChange={inputEventDomin}
                                        // value={domain}
                                        multiple
                                        
                                      >
                                        <option value="">
                                          ---Select Domain---
                                        </option>
                                        {allDomain.map((i) => {
                                          return (
                                            <option value={i.id}>
                                              {i.targeturl}
                                            </option>
                                          );
                                        })}

                                        {allDomain.map((i) => {
                                          return (
                                            <option
                                              selected={
                                                domain.indexOf(i.targeturl) > -1
                                                  ? true
                                                  : false
                                              }
                                              value={i.targeturl}
                                            >
                                              {i.targeturl}
                                            </option>
                                          );
                                        })}
                                      </select> */}

                                          <Select
                                            onChange={inputEventDomin}
                                            value={domainSelect}
                                            isDisabled={allDomainSelected}

                                            options={allDomainSelect}
                                            isMulti
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            placeholder="Select..."
                                          />
                                        </div>
                                      </div>





                                    </div>
                                    <div className="uk-width-1-2@s">
                                      <div className="uk-grid-small" uk-grid="">
                                        <div className="uk-width-1-2@s">
                                          <div className="uk-grid-small" uk-grid="">
                                            <div className="uk-width-1-2@s">
                                              <div className="uk-margin">
                                                <label className="uk-form-label">
                                                  {" "}
                                                  Max Domain{" "}
                                                  {/* <span className="uk-text-danger">*</span> */}
                                                </label>
                                                <div className="uk-form-controls">
                                                  <input
                                                    className="uk-input"
                                                    name="password"
                                                    type="number"
                                                    placeholder="Enter Max Domain"
                                                    value={maxDomain}
                                                    onChange={inputEventMaxDomain}

                                                  />
                                                </div>
                                              </div>
                                            </div>
                                            <div className="uk-width-1-2@s">
                                              <div className="uk-margin">
                                                <label className="uk-form-label">
                                                  Any Third-Party Check{" "}
                                                  <span className="uk-text-danger">*</span>
                                                </label>
                                                <div className="uk-form-controls">
                                                  <input
                                                    className="uk-checkbox"
                                                    type="checkbox"
                                                    onChange={inputEventAnyThirdPartyCheck}
                                                    id="AnyThirdPartyCheck"
                                                  />
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                        </div>

                                        <div className="uk-width-1-2@s">
                                          <div className="uk-margin">
                                            <label className="uk-form-label">
                                              Third-Party Check{" "}
                                              <span className="uk-text-danger">*</span>
                                            </label>
                                            <div className="uk-form-controls">
                                              <select
                                                class="uk-select Post_Name"
                                                name="post_name"
                                                onChange={inputEventThirdPartyCheck}
                                                id="ThirdPartyCheck"
                                                required
                                              >
                                                <option value="">---Select---</option>
                                                <option value="2">Yes </option>
                                                <option value="1">No </option>
                                                <option value="0">NA </option>
                                              </select>
                                            </div>
                                          </div>
                                        </div>




                                      </div>

                                    </div>
                                  </div>
                                </div>
                                <div className="uk-width-1-2@s">
                                  <div className="uk-grid-small" uk-grid="">
                                    <div className="uk-width-1-2@s">
                                      <div className="uk-grid-small" uk-grid="">
                                        <div className="uk-width-1-4@s">
                                          <div className="uk-margin">
                                            <label className="uk-form-label">
                                              Any Accept Link_Insertions{" "}
                                              <span className="uk-text-danger">*</span>
                                            </label>
                                            <div className="uk-form-controls">
                                              <input
                                                className="uk-checkbox"
                                                type="checkbox"
                                                onChange={inputEventAnyAcceptLinkInsertions}
                                                id="anyAcceptLinkInsertions"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="uk-width-3-4@s">
                                          <div className="uk-margin">
                                            <label className="uk-form-label">
                                              Accept Link Insertions{" "}
                                              <span className="uk-text-danger">*</span>
                                            </label>
                                            <div className="uk-form-controls">
                                              <select
                                                class="uk-select Post_Name"
                                                name="post_name"
                                                onChange={inputEventAcceptLinkInsertions}

                                                required
                                                id={"AcceptLinkInsertions"}
                                              >
                                                <option value="">---Select---</option>
                                                <option value="2">Yes </option>

                                                <option value="1">No </option>
                                                <option value="0">NA </option>
                                              </select>
                                            </div>
                                          </div>
                                        </div>

                                      </div>

                                    </div>
                                    <div className="uk-width-1-2@s">
                                      <div className="uk-grid-small" uk-grid="">
                                        <div className="uk-width-1-4@s">
                                          <div className="uk-margin">
                                            <label className="uk-form-label">
                                              Any Accept Authorship{" "}
                                              <span className="uk-text-danger">*</span>
                                            </label>
                                            <div className="uk-form-controls">
                                              <input
                                                className="uk-checkbox"
                                                type="checkbox"
                                                onChange={inputEventAnyAcceptAuthorship}
                                                id={"AnyAcceptAuthorship"}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        <div className="uk-width-3-4@s">
                                          <div className="uk-margin">
                                            <label className="uk-form-label">
                                              Accept Authorship{" "}
                                              <span className="uk-text-danger">*</span>
                                            </label>
                                            <div className="uk-form-controls">
                                              <select
                                                class="uk-select Post_Name"
                                                name="post_name"
                                                onChange={inputEventAcceptAuthorship}
                                                id={"AcceptAuthorship"}

                                                required


                                              >
                                                <option value="">---Select---</option>

                                                <option value="2">Yes </option>

                                                <option value="1">No </option>
                                                <option value="0">NA </option>
                                              </select>
                                            </div>
                                          </div>
                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="uk-width-1-2@s">
                                  <div className="uk-grid-small" uk-grid="">
                                    <div className="uk-width-1-6@s">
                                      <div className="uk-margin">
                                        <label className="uk-form-label">
                                          {" "}
                                          Any Niche{" "}
                                        </label>
                                        <div className="uk-form-controls">
                                          <input
                                            className="uk-checkbox"
                                            type="checkbox"
                                            onChange={inputEventAnyNiche}
                                            id="any_niche"

                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="uk-width-5-6@s">
                                      <div className="uk-margin">
                                        <label className="uk-form-label">
                                          {" "}
                                          Niche <span className="uk-text-danger">*</span>
                                        </label>
                                        <div className="uk-form-controls">


                                          <Select
                                            // onChange={inputEventDomin}
                                            onChange={inputEventNiche}
                                            value={selectedNiche}
                                            options={allNiche}
                                            isMulti
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            isDisabled={anyNiche}

                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="uk-width-1-2@s">
                                  <div className="uk-grid-small" uk-grid="">
                                    <div className="uk-width-1-6@s">
                                      <div className="uk-margin">
                                        <label className="uk-form-label">
                                          {" "}
                                          Any Sub Niche{" "}
                                        </label>
                                        <div className="uk-form-controls">
                                          <input
                                            className="uk-checkbox"
                                            type="checkbox"
                                            onChange={inputEventAnySubNiche}
                                            id="any_sub_niche"

                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="uk-width-5-6@s">
                                      <div className="uk-margin">
                                        <label className="uk-form-label">
                                          {" "}
                                          Sub Niche <span className="uk-text-danger">*</span>
                                        </label>
                                        <div className="uk-form-controls">


                                          <Select
                                            onChange={inputEventSubNiche}
                                            value={selectedSubNiche}

                                            options={allSubNiche}
                                            isMulti
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            isDisabled={anySubNiche}

                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>





                              </div>



                              <hr />










                              <div className="uk-grid-small" uk-grid="">
                                <div className="uk-width-1-2@s">
                                  <div className="uk-grid-small" uk-grid="">
                                    <div className="uk-width-1-2@s">
                                      <div className="uk-width-1-1@s uk-margin-small-top">

                                        {!show_add_additional_conditional_form
                                          ?
                                          <>
                                            <button
                                              type="submit"
                                              className="uk-button  uk-button-primary custombtn"
                                              name="submit_candidate"
                                              style={{ "background-color": "#4CAF50" }}
                                            >
                                              <i class="fa fa-save"></i> Update Product
                                            </button>
                                          </>

                                          : <>
                                            <h3><b>Add Additional Conditional</b></h3>
                                          </>}
                                      </div>
                                    </div>
                                    {/* <div className="uk-width-1-2@s">
                                     
                                    </div> */}
                                  </div>
                                </div>
                                <div className="uk-width-1-2@s">


                                  <div className="uk-width-1-1@s uk-text-right uk-margin-small-bottom">

                                    <button
                                      type='button'
                                      className="uk-button  uk-button-danger custombtn"
                                      name="submit_candidate"
                                      onClick={() => { show_add_additional_conditional_form ? set_show_add_additional_conditional_form(false) : set_show_add_additional_conditional_form(true) }}
                                    >
                                      {show_add_additional_conditional_form ? "Remove" : "Add additional conditional"}

                                    </button>
                                  </div>

                                </div>
                              </div>







                              {show_add_additional_conditional_form ? <>
                                <hr />


                                <div className="uk-grid-small" uk-grid="">
                                  <div className="uk-width-1-2@s">
                                    <div className="uk-grid-small" uk-grid="">
                                      <div className="uk-width-1-2@s">
                                        <div className="uk-margin">
                                          <label className="uk-form-label">
                                            Select Type{" "}
                                            <span className="uk-text-danger">*</span>
                                          </label>
                                          <div className="uk-form-controls">
                                            <select
                                              className="uk-select Post_Name"
                                              name="post_name"
                                              onChange={(e) => { setDataType(e.target.value) }}



                                            >
                                              <option value="">---Select---</option>
                                              <option selected={dataType == '2' ? true : false} value="2">AND </option>
                                              <option selected={dataType == '1' ? true : false} value="1">OR </option>
                                              <option selected={dataType == '0' ? true : false} value="0">NOT </option>
                                            </select>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="uk-width-1-2@s">
                                        <div className="uk-grid-small" uk-grid="">
                                          <div className="uk-width-1-4@s">
                                            <div className="uk-margin">
                                              <label className="uk-form-label">

                                                Any DR{" "}
                                              </label>
                                              <div className="uk-form-controls">
                                                <input
                                                  className="uk-checkbox"
                                                  type="checkbox"
                                                  checked={anyDR_aac}
                                                  onChange={(e) => { setAnyDR_aac(e.target.checked) }}
                                                />
                                              </div>
                                            </div>

                                          </div>
                                          <div className="uk-width-3-4@s">
                                            <div className="uk-grid-small" uk-grid="">
                                              <div className="uk-width-1-2@s">
                                                <div className="uk-margin">
                                                  <label className="uk-form-label">
                                                    {" "}
                                                    Min-DR{" "}
                                                  </label>
                                                  <div className="uk-form-controls">
                                                    <input
                                                      className="uk-input"
                                                      type="number"

                                                      min={0}
                                                      placeholder="Enter Min-DR"
                                                      value={minDR_aac}
                                                      onChange={(e) => { setMinDR_aac(e.target.value) }}
                                                      required={anyDR_aac ? false : true}
                                                      readOnly={anyDR_aac ? true : false}

                                                    />
                                                  </div>
                                                </div>

                                              </div>

                                              <div className="uk-width-1-2@s">
                                                <div className="uk-margin">
                                                  <label className="uk-form-label">
                                                    {" "}
                                                    Max-DR{" "}
                                                  </label>
                                                  <div className="uk-form-controls">
                                                    <input
                                                      className="uk-input"


                                                      type="number"
                                                      min={0}
                                                      placeholder="Enter Max-DR"
                                                      value={maxDR_aac}
                                                      onChange={(e) => { setMaxDR_aac(e.target.value) }}
                                                      required={anyDR_aac ? false : true}
                                                      readOnly={anyDR_aac ? true : false}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="uk-width-1-2@s">

                                    <div className="uk-grid-small" uk-grid="">
                                      <div className="uk-width-1-2@s">
                                        <div className="uk-grid-small" uk-grid="">
                                          <div className="uk-width-1-4@s">
                                            <div className="uk-margin">
                                              <label className="uk-form-label">
                                                Any RD{" "}
                                                <span className="uk-text-danger"></span>
                                              </label>
                                              <div className="uk-form-controls">
                                                <input
                                                  className="uk-checkbox"
                                                  type="checkbox"
                                                  checked={anyRD_aac}
                                                  onChange={(e) => { setAnyRD_aac(e.target.checked) }}
                                                />
                                              </div>
                                            </div>

                                          </div>
                                          <div className="uk-width-3-4@s">

                                            <div className="uk-grid-small" uk-grid="">
                                              <div className="uk-width-1-2@s">
                                                <div className="uk-margin">
                                                  <label className="uk-form-label">
                                                    {" "}
                                                    Min RD{" "}
                                                    <span className="uk-text-danger">*</span>
                                                  </label>
                                                  <div className="uk-form-controls">
                                                    <input
                                                      className="uk-input"
                                                      name="password"
                                                      type="number"
                                                      placeholder="Enter Min-RD"

                                                      value={minRD_aac}
                                                      onChange={(e) => { setMinRD_aac(e.target.value) }}
                                                      required={anyRD_aac ? false : true}
                                                      readOnly={anyRD_aac ? true : false}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="uk-width-1-2@s">
                                                <div className="uk-margin">
                                                  <label className="uk-form-label">
                                                    {" "}
                                                    Max RD{" "}
                                                    <span className="uk-text-danger">*</span>
                                                  </label>
                                                  <div className="uk-form-controls">
                                                    <input
                                                      className="uk-input"

                                                      name="max_rd"
                                                      type="number"
                                                      placeholder="Enter Max-RD"
                                                      value={maxRD_aac}
                                                      onChange={(e) => { setMaxRD_aac(e.target.value) }}
                                                      required={anyRD_aac ? false : true}
                                                      readOnly={anyRD_aac ? true : false}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>




                                          </div>


                                        </div>


                                      </div>
                                      <div className="uk-width-1-2@s">
                                        <div className="uk-grid-small" uk-grid="">
                                          <div className="uk-width-1-4@s">
                                            <div className="uk-margin">
                                              <label className="uk-form-label">
                                                Any Average Traffic{" "}
                                                <span className="uk-text-danger"></span>
                                              </label>
                                              <div className="uk-form-controls">
                                                <input
                                                  className="uk-checkbox"
                                                  type="checkbox"
                                                  checked={anyAverageTraffic_aac}
                                                  onChange={(e) => { setAnyAverageTraffic_aac(e.target.checked) }}
                                                />
                                              </div>
                                            </div>

                                          </div>
                                          <div className="uk-width-3-4@s">
                                            <div className="uk-grid-small" uk-grid="">
                                              <div className="uk-width-1-2@s">
                                                <div className="uk-margin">
                                                  <label className="uk-form-label">
                                                    {" "}
                                                    Min-Average Traffic{" "}
                                                    <span className="uk-text-danger">*</span>
                                                  </label>
                                                  <div className="uk-form-controls">
                                                    <input
                                                      className="uk-input"
                                                      name="password"
                                                      type="number"
                                                      placeholder="Enter Min-Average Traffic"
                                                      value={minAverageTraffic_aac}
                                                      onChange={(e) => { setMinAverageTraffic_aac(e.target.value) }}
                                                      required={anyAverageTraffic_aac ? false : true}
                                                      readOnly={anyAverageTraffic_aac ? true : false}

                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="uk-width-1-2@s">
                                                <div className="uk-margin">
                                                  <label className="uk-form-label">
                                                    {" "}
                                                    Max-Average Traffic{" "}
                                                    <span className="uk-text-danger">*</span>
                                                  </label>
                                                  <div className="uk-form-controls">
                                                    <input
                                                      className="uk-input"

                                                      name="max_average_traffic"
                                                      type="number"
                                                      placeholder="Enter Max-Average Traffic"
                                                      value={maxAverageTraffic_aac}
                                                      onChange={(e) => { setMaxAverageTraffic_aac(e.target.value) }}
                                                      required={anyAverageTraffic_aac ? false : true}
                                                      readOnly={anyAverageTraffic_aac ? true : false}

                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>



                                          </div>


                                        </div>


                                      </div>

                                    </div>

                                  </div>
                                </div>

                                <div className="uk-grid-small" uk-grid="">


                                  <div className="uk-width-1-4@s">
                                    <div className="uk-margin">
                                      <label className="uk-form-label">
                                        Types{" "}
                                        <span className="uk-text-danger">*</span>
                                      </label>
                                      <div className="uk-form-controls">
                                        <select
                                          class="uk-select Post_Name"
                                          name="post_name"
                                          onChange={inputEventTypesAAC}
                                          multiple
                                          required
                                        >
                                          {signals_types.map((i) => {
                                            return <option
                                              selected={
                                                type_aac.indexOf(i.id) > -1
                                                  ? true
                                                  : false
                                              }
                                              value={i.id}>{i.name}</option>;
                                          })}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="uk-width-1-4@s">
                                    <div className="uk-margin">
                                      <label className="uk-form-label">
                                        Indexed
                                        <span className="uk-text-danger">*</span>
                                      </label>
                                      <div className="uk-form-controls">
                                        <select
                                          class="uk-select Post_Name"
                                          name="post_name"

                                          onChange={inputEventIndexedAAC}
                                          multiple
                                          required
                                        >
                                          {/* <option value="">---Select Indexed---</option> */}
                                          <option selected={indexed_aac.indexOf(2) > -1 ? true : false} value="2" >
                                            Yes
                                          </option>
                                          <option selected={indexed_aac.indexOf(1) > -1 ? true : false} value="1" >
                                            No
                                          </option>
                                          <option selected={indexed_aac.indexOf(0) > -1 ? true : false} value="0" >
                                            NA
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="uk-width-1-4@s">
                                    <div className="uk-margin">
                                      <label className="uk-form-label">
                                        Dofollow
                                        <span className="uk-text-danger">*</span>
                                      </label>
                                      <div className="uk-form-controls">
                                        <select
                                          class="uk-select Post_Name"
                                          name="post_name"
                                          onChange={inputEventDofollowAAC}
                                          multiple
                                          required
                                        >
                                          {/* <option value="">---Select Dofollow---</option> */}
                                          <option selected={dofollow_aac.indexOf(2) > -1 ? true : false} value="2" >
                                            Yes
                                          </option>
                                          <option selected={dofollow_aac.indexOf(1) > -1 ? true : false} value="1" >
                                            No
                                          </option>
                                          <option selected={dofollow_aac.indexOf(0) > -1 ? true : false} value="0" >
                                            NA
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="uk-width-1-4@s">
                                    <div className="uk-margin">
                                      <label className="uk-form-label">
                                        Status
                                        <span className="uk-text-danger">*</span>
                                      </label>
                                      <div className="uk-form-controls">
                                        <select
                                          class="uk-select Post_Name"
                                          name="post_name"
                                          onChange={inputEventStatusAAC}
                                          multiple
                                          required


                                        >


                                          {all_notes.map((i) => {
                                            return <option
                                              selected={
                                                status_aac.indexOf(i.id) > -1
                                                  ? true
                                                  : false
                                              }
                                              value={i.id}>{i.name}</option>;
                                          })}
                                        </select>
                                      </div>
                                    </div>
                                  </div>


                                  <div className="uk-width-1-2@s">
                                    <div className="uk-grid-small" uk-grid="">
                                      <div className="uk-width-1-2@s">
                                        <div className="uk-margin">
                                          <label className="uk-form-label">
                                            {" "}
                                            Domain{" "}
                                            {/* <span className="uk-text-danger">*</span> */}
                                          </label>
                                          <div className="uk-form-controls" style={{ width: "100%" }}>

                                            <Select
                                              onChange={inputEventDominAAC}
                                              options={allDomainSelect}

                                              isMulti
                                              className="basic-multi-select"
                                              classNamePrefix="select"
                                              value={domainSelect_aac}
                                              placeholder="Select..."
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="uk-width-1-2@s">
                                        <div className="uk-grid-small" uk-grid="">
                                          <div className="uk-width-1-2@s">
                                            <div className="uk-grid-small" uk-grid="">
                                              <div className="uk-width-1-2@s">
                                                <div className="uk-margin">
                                                  <label className="uk-form-label">


                                                    Max Domain{" "}
                                                    {/* <span className="uk-text-danger">*</span> */}
                                                  </label>
                                                  <div className="uk-form-controls">
                                                    <input
                                                      className="uk-input"
                                                      name="password"
                                                      type="number"
                                                      placeholder="Enter Max Domain"
                                                      value={maxDomain_aac}
                                                      onChange={(e) => { setMaxDomain_aac(e.target.value) }}


                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="uk-width-1-2@s">
                                                <div className="uk-margin">
                                                  <label className="uk-form-label">
                                                    Any Third-Party Check{" "}
                                                    <span className="uk-text-danger">*</span>
                                                  </label>
                                                  <div className="uk-form-controls">
                                                    <input
                                                      className="uk-checkbox"
                                                      type="checkbox"
                                                      checked={anyThirdPartyCheck_aac}
                                                      onChange={(e) => { setAnyThirdPartyCheck_aac(e.target.checked) }}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="uk-width-1-2@s">
                                            <div className="uk-margin">
                                              <label className="uk-form-label">
                                                Third-Party Check{" "}
                                                <span className="uk-text-danger">*</span>
                                              </label>
                                              <div className="uk-form-controls">
                                                <select
                                                  class="uk-select Post_Name"
                                                  name="post_name"
                                                  onChange={(e) => { setThirdPartyCheck_aac(e.target.value) }}
                                                  required={anyThirdPartyCheck_aac ? false : true}
                                                  disabled={anyThirdPartyCheck_aac ? true : false}
                                                  value={ThirdPartyCheck_aac}
                                                >
                                                  <option value="">---Select---</option>
                                                  <option value="2">Yes </option>
                                                  <option value="1">No </option>
                                                  <option value="0">NA </option>
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="uk-width-1-2@s">
                                    <div className="uk-grid-small" uk-grid="">
                                      <div className="uk-width-1-2@s">
                                        <div className="uk-grid-small" uk-grid="">
                                          <div className="uk-width-1-4@s">
                                            <div className="uk-margin">
                                              <label className="uk-form-label">
                                                Any Accept Link_Insertions
                                                <span className="uk-text-danger">*</span>
                                              </label>
                                              <div className="uk-form-controls">
                                                <input
                                                  className="uk-checkbox"
                                                  type="checkbox"
                                                  checked={anyAcceptLinkInsertions_aac}
                                                  onChange={(e) => { setAnyAcceptLinkInsertions_aac(e.target.checked) }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="uk-width-3-4@s">
                                            <div className="uk-margin">
                                              <label className="uk-form-label">
                                                Accept Link Insertions{" "}
                                                <span className="uk-text-danger">*</span>
                                              </label>
                                              <div className="uk-form-controls">
                                                <select
                                                  class="uk-select Post_Name"
                                                  name="post_name"
                                                  onChange={(e) => { setAcceptLinkInsertions_aac(e.target.value) }}
                                                  required={anyAcceptLinkInsertions_aac ? false : true}
                                                  disabled={anyAcceptLinkInsertions_aac ? true : false}
                                                  value={AcceptLinkInsertions_aac}
                                                >
                                                  <option value="">---Select---</option>
                                                  <option value="2">Yes </option>
                                                  <option value="1">No </option>
                                                  <option value="0">NA </option>
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="uk-width-1-2@s">
                                        <div className="uk-grid-small" uk-grid="">
                                          <div className="uk-width-1-4@s">
                                            <div className="uk-margin">
                                              <label className="uk-form-label">
                                                Any Accept Authorship{" "}
                                                <span className="uk-text-danger">*</span>
                                              </label>
                                              <div className="uk-form-controls">
                                                <input
                                                  className="uk-checkbox"
                                                  type="checkbox"

                                                  checked={anyAcceptAuthorship_aac}
                                                  onChange={(e) => { setAnyAcceptAuthorship_aac(e.target.checked) }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                          <div className="uk-width-3-4@s">
                                            <div className="uk-margin">
                                              <label className="uk-form-label">
                                                Accept Authorship{" "}
                                                <span className="uk-text-danger">*</span>
                                              </label>
                                              <div className="uk-form-controls">
                                                <select
                                                  class="uk-select Post_Name"
                                                  name="post_name"
                                                  onChange={(e) => { setAcceptAuthorship_aac(e.target.value) }}
                                                  value={AcceptAuthorship_aac}
                                                  required={anyAcceptAuthorship_aac ? false : true}
                                                  disabled={anyAcceptAuthorship_aac ? true : false}
                                                >
                                                  <option value="">---Select---</option>
                                                  <option value="2">Yes </option>
                                                  <option value="1">No </option>
                                                  <option value="0">NA </option>
                                                </select>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="uk-width-1-2@s">
                                    <div className="uk-grid-small" uk-grid="">
                                      <div className="uk-width-1-6@s">
                                        <div className="uk-margin">
                                          <label className="uk-form-label">
                                            Any Niche
                                          </label>
                                          <div className="uk-form-controls">
                                            <input
                                              className="uk-checkbox"
                                              type="checkbox"
                                              checked={anyNiche_aac}
                                              onChange={(e) => { setAnyNiche_aac(e.target.checked) }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="uk-width-5-6@s">
                                        <div className="uk-margin">
                                          <label className="uk-form-label">

                                            Niche <span className="uk-text-danger">*</span>
                                          </label>
                                          <div className="uk-form-controls">
                                            <Select
                                              onChange={inputEventNicheAAC}
                                              options={allNiche}
                                              value={nicheSelect_aac}
                                              isMulti
                                              className="basic-multi-select"
                                              classNamePrefix="select"
                                              isDisabled={anyNiche_aac}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="uk-width-1-2@s">
                                    <div className="uk-grid-small" uk-grid="">
                                      <div className="uk-width-1-6@s">
                                        <div className="uk-margin">
                                          <label className="uk-form-label">
                                            {" "}
                                            Any Sub Niche{" "}
                                          </label>
                                          <div className="uk-form-controls">
                                            <input
                                              className="uk-checkbox"
                                              type="checkbox"
                                              checked={anySubNiche_aac}
                                              onChange={(e) => { setSubAnyNiche_aac(e.target.checked) }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <div className="uk-width-5-6@s">
                                        <div className="uk-margin">
                                          <label className="uk-form-label">
                                            {" "}
                                            Sub Niche <span className="uk-text-danger">*</span>
                                          </label>
                                          <div className="uk-form-controls">


                                            <Select
                                              onChange={inputEventSubNicheAAC}
                                              value={subNicheSelect_aac}

                                              options={allSubNiche}
                                              isMulti
                                              className="basic-multi-select"
                                              classNamePrefix="select"
                                              isDisabled={anySubNiche_aac}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>





                                </div>
                                <hr />


                                <button
                                  type="submit"
                                  className="uk-button  uk-button-primary custombtn"
                                  name="submit_candidate"
                                  style={{ "background-color": "#4CAF50" }}
                                >
                                  <i class="fa fa-save"></i> Update Product
                                </button>
                              </> : <></>}
                            </form>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
